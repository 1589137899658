import { PropagateLoader } from 'react-spinners';
import styles from './spinner.module.scss';

interface IProps {
  show: boolean;
}

const SpinnerModal = (props: IProps) => {
  return (
    <>
      {props.show && (
        <div
          className={[
            styles.spinnerContainer,
            'w-100 h-100 d-flex align-items-center justify-content-center',
          ].join(' ')}
        >
          <PropagateLoader color="#0092D0" />
        </div>
      )}
    </>
  );
};

export default SpinnerModal;
