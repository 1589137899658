import devConfig from './development.json';

const config: Config = devConfig;
export default config;

export interface Config {
  firebase: FirebaseConfig;
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}
