const PayhereModel = () => {
  // @ts-ignore
  window.payhere.onCompleted = function onCompleted(orderId) {
    console.log('Payment completed. OrderID:' + orderId);
    // alert("New Payhere payment: OrderID: " + orderId)
    //Note: validate the payment and show success or failure page to the customer
  };

  // Called when user closes the payment without completing
  // @ts-ignore
  window.payhere.onDismissed = function onDismissed() {
    //Note: Prompt user to pay again or show an error page
    console.log('Payment dismissed');
  };

  // Called when error happens when initializing payment such as invalid parameters
  // @ts-ignore
  window.payhere.onError = function onError(error) {
    // Note: show an error page
    console.log('Error:' + error);
  };

  return (
    <div>
      <div id="payhere-modal"></div>
    </div>
  );
};

export default PayhereModel;
