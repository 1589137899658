import React from 'react';
import NavBar from '../NavBar/NavBar';
import { Col, Row } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import './PaymentSuccessScreen.scss';
import { FaCheckCircle } from 'react-icons/fa';
import { useParams } from 'react-router';

const PaymentSuccessScreen = () => {
  const params = useParams() as { orderId: string };
  return (
    <>
      <div className="fade-in">
        <NavBar active="payment" background="bg-background" />
        <section className="d-flex justify-content-center align-items-center container mb-2 notice-display">
          <Row>
            <Col>
              <Row className="success-header d-flex justify-content-center mb-2">
                Payment Successful
              </Row>
              <Row className="notice-message d-flex justify-content-center mb-3">
                Check the email you entered to check the receipt.
              </Row>
              <Row className="d-flex justify-content-center mb-3">
                <FaCheckCircle size={83} color="#1BD164" />
              </Row>
              <Row className="transaction-text d-flex justify-content-center">
                Transaction Id - {params.orderId}
              </Row>
            </Col>
          </Row>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default PaymentSuccessScreen;
