import React from 'react';
import NavBar from '../NavBar/NavBar';
import { Col, Row } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import './PaymentSuccessScreen.scss';
import { RiCloseCircleFill } from 'react-icons/ri';

const PaymentUnSuccessScreen = () => {
  return (
    <>
      <div className="fade-in">
        <NavBar active="payment" background="bg-background" />
        <section className="d-flex justify-content-center align-items-center container mb-2 notice-display">
          <Row>
            <Col>
              <Row className="success-header d-flex justify-content-center mb-2">
                Payment Unsuccessful
              </Row>
              <Row className="notice-message d-flex justify-content-center mb-3">
                Payment was unsuccessful due to error
              </Row>
              <Row className="d-flex justify-content-center mb-3">
                <RiCloseCircleFill size={83} color="#EF4F4F" />
              </Row>
              <Row className="transaction-text d-flex justify-content-center">
                Please Try Again Later
              </Row>
            </Col>
          </Row>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default PaymentUnSuccessScreen;
