import React, { useState } from 'react';
import NavBar from '../NavBar/NavBar';
import { Button, Col, Form, Row } from 'react-bootstrap';
import './PaymentScreen.scss';
import Footer from '../Footer/Footer';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import { InitPaymentDTO } from '../../dtos/InitPaymentDTO';
import { useNavigate } from 'react-router';
import SpinnerModal from '../../shared/ui-elements/spinner/spinner';

const PaymentScreen = () => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [isInvalidName, setIsInvalidName] = useState<boolean>(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
  const [isInvalidPhone, setIsInvalidPhone] = useState<boolean>(false);
  const [isInvalidAddress, setIsInvalidAddress] = useState<boolean>(false);
  const [isInvalidReason, setIsInvalidReason] = useState<boolean>(false);
  const [isInvalidAmount, setIsInvalidAmount] = useState<boolean>(false);
  const [invalidNameErr, setInvalidNameErr] = useState<string>('');
  const [invalidEmailErr, setInvalidEmailErr] = useState<string>('');
  const [invalidPhoneErr, setInvalidPhoneErr] = useState<string>('');
  const [invalidAddressErr, setInvalidAddressErr] = useState<string>('');
  const [invalidReasonErr, setInvalidReasonErr] = useState<string>('');
  const [invalidAmountErr, setInvalidAmountErr] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isValidForm = () => {
    let isValid = true;
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!name || name.length < 3) {
      setIsInvalidName(true);
      if (name) {
        setInvalidNameErr('Your name should contain at least 3 characters');
      } else {
        setInvalidNameErr('Your name is required');
      }
      isValid = false;
    } else {
      setIsInvalidName(false);
      setInvalidNameErr('');
    }

    if (!email || !regex.test(email)) {
      setIsInvalidEmail(true);
      if (email) {
        setInvalidEmailErr('Your email is invalid');
      } else {
        setInvalidEmailErr('Your email is required');
      }
      isValid = false;
    } else {
      setIsInvalidEmail(false);
      setInvalidEmailErr('');
    }
    const codeAppliedPhone: boolean = phone.includes('+');

    if (
      !phone ||
      (codeAppliedPhone && phone.length > 15) ||
      (codeAppliedPhone && phone.length < 12) ||
      (!codeAppliedPhone && phone.length > 10) ||
      (!codeAppliedPhone && phone.length < 10)
    ) {
      setIsInvalidPhone(true);
      if (!phone) {
        setInvalidPhoneErr('Your contact number is required');
      } else if (
        (!codeAppliedPhone && phone.length > 10) ||
        (!codeAppliedPhone && phone.length < 10)
      ) {
        setInvalidPhoneErr('Your contact number is invalid');
      } else if (
        (codeAppliedPhone && phone.length > 15) ||
        (codeAppliedPhone && phone.length < 12)
      ) {
        setInvalidPhoneErr('Your contact number is invalid');
      } else {
        setInvalidPhoneErr('Your contact number is required');
      }
      isValid = false;
    } else {
      setIsInvalidPhone(false);
      setInvalidPhoneErr('');
    }

    if (!address || address.length < 10) {
      setIsInvalidAddress(true);
      if (address) {
        setInvalidAddressErr('Your address should contain at least 10 characters');
      } else {
        setInvalidAddressErr('Your address is required');
      }
      isValid = false;
    } else {
      setIsInvalidAddress(false);
      setInvalidAddressErr('');
    }

    if (!reason || reason.length < 10) {
      setIsInvalidReason(true);
      if (reason) {
        setInvalidReasonErr('Your reason for payment should contain at least 10 characters');
      } else {
        setInvalidReasonErr('Your reason for payment is required');
      }
      isValid = false;
    } else {
      setIsInvalidReason(false);
      setInvalidReasonErr('');
    }

    if (!amount) {
      setIsInvalidAmount(true);
      setInvalidAmountErr('Amount is required');
      isValid = false;
    } else {
      setIsInvalidAmount(false);
      setInvalidAmountErr('');
    }

    return isValid;
  };

  const functions = useFunctions();
  const initPaymentRef = httpsCallable(functions, 'initPayment');
  const payCourse = () => {
    const paymentData: InitPaymentDTO = {
      fullName: name,
      email: email,
      contactNo: phone,
      address: address,
      paymentReason: reason,
      payAmount: amount,
    };

    if (isValidForm()) {
      setIsLoading(true);
      initPaymentRef(paymentData)
        .then((res) => {
          setIsLoading(false);
          try {
            window.payhere.startPayment(res.data);
          } catch (err) {
            console.log('Err', err);
            navigate('/payment/unsuccess');
          }
          // @ts-ignore
          window.payhere.onCompleted = function onCompleted(orderId) {
            setName('');
            setEmail('');
            setPhone('');
            setAddress('');
            setReason('');
            setAmount('');
            navigate(`/payment/success/${orderId}`);
          };

          // @ts-ignore
          window.payhere.onDismissed = function onDismissed() {
            // Note: Prompt user to pay again or show an error page
          };

          // Error occurred
          // @ts-ignore
          window.payhere.onError = function onError(error) {
            // Note: show an error page
            console.log('Error:' + error);

            navigate('/payment/unsuccess');
          };
        })
        .catch((err) => {
          setIsLoading(false);
          navigate('/payment/unsuccess');
          console.log('Err', err);
        });
    }
  };
  return (
    <>
      <SpinnerModal show={isLoading} />
      <div className="fade-in">
        <NavBar active="payment" background="bg-background" />
        <section className="container mt-5 mb-5 payment-section">
          <Row className="px-2">
            <Col>
              <Row>
                <Col className="payment-title-one text-center">Skill Surf Payments</Col>
              </Row>
              <Row>
                <Col className="payment-heading-message mt-2">
                  Make sure to use a valid email address to get the payment receipt.
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col className="col-12 col-sm-12 col-md-6 col-lg-6" style={{ marginTop: '2rem' }}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label className="app-input-lable">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        className="custom-form-control"
                        value={name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setName(e.target.value);
                        }}
                        onKeyUp={() => {
                          setIsInvalidName(false);
                        }}
                      />

                      {isInvalidName ? <p className="text-error">{invalidNameErr}</p> : <></>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label className="app-input-lable">Email*</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        className="custom-form-control"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setEmail(e.target.value);
                        }}
                        onKeyUp={() => {
                          setIsInvalidEmail(false);
                        }}
                      />
                      {isInvalidEmail ? <p className="text-error">{invalidEmailErr}</p> : <></>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formContactNumber">
                      <Form.Label className="app-input-lable">Contact Number*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="+94 123456789"
                        className="custom-form-control"
                        value={phone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setPhone(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          const key = e.key;
                          if (key !== '+' && !/[0-9]/.test(key)) {
                            e.preventDefault();
                          }
                        }}
                        onKeyUp={() => {
                          setIsInvalidPhone(false);
                        }}
                      />
                      {isInvalidPhone ? <p className="text-error">{invalidPhoneErr}</p> : <></>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formAddress">
                      <Form.Label className="app-input-lable">Address*</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Address"
                        value={address}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAddress(e.target.value);
                        }}
                        onKeyUp={() => {
                          setIsInvalidAddress(false);
                        }}
                      />

                      {isInvalidAddress ? <p className="text-error">{invalidAddressErr}</p> : <></>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formReason">
                      <Form.Label className="app-input-lable">Reason for Payment*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ex: Embedded Product Design for IoT"
                        className="custom-form-control"
                        value={reason}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setReason(e.target.value);
                        }}
                        onKeyUp={() => {
                          setIsInvalidReason(false);
                        }}
                      />
                      {isInvalidReason ? <p className="text-error">{invalidReasonErr}</p> : <></>}
                    </Form.Group>

                    <Form.Group className="mb-5" controlId="formAmount">
                      <Form.Label className="app-input-lable">Payment Amount (LKR)*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="2500"
                        className="custom-form-control"
                        value={amount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setAmount(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          const key = e.key;
                          if (!/[0-9]/.test(key)) {
                            e.preventDefault();
                          }
                        }}
                        onKeyUp={() => {
                          setIsInvalidAmount(false);
                        }}
                      />
                      {isInvalidAmount ? <p className="text-error">{invalidAmountErr}</p> : <></>}
                    </Form.Group>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Button
                          className="text-white register-button px-3 py-2 mb-3"
                          style={{
                            fontWeight: 600,
                            width: 'auto',
                          }}
                          id="payhere-payment"
                          onClick={payCourse}
                        >
                          Confirm Payment
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default PaymentScreen;
