import React from 'react';
import './Footer.scss';
import { Col, Row } from 'react-bootstrap';
import payHereLogo from './assets/payhere.webp';

const Footer = () => {
  return (
    <>
      <div className="container py-4 footer-text">
        <Row>
          <Col>
            <Row className="align-items-center">
              <Col className="footer-text">
                © 2023 Skill Surf <span className="footer-dash">| </span> Privacy{' '}
                <span className="footer-dash">| </span> Terms
              </Col>
              <Col className="footer-text d-flex justify-content-end align-items-center">
                powered by
                <span>
                  <img
                    src={payHereLogo}
                    alt="payHere logo"
                    style={{ width: '78px', height: '34px' }}
                  />
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
