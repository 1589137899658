import React from 'react';
import Logo from './assets/logo.webp';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import Button from "react-bootstrap/Button";
import './NavBar.scss';
import { NavLink } from 'react-router-dom';

interface Iprops {
  active?: string;
  background?: string;
}

const NavBar = (props: Iprops) => {
  return (
    <div className={`navbar-container ${props.background}`}>
      <div className="navbar-contents">
        <Navbar collapseOnSelect expand="lg">
          <Container fluid>
            <NavLink to={'/'}>
              <img src={Logo} alt="skill_surf logo" />
            </NavLink>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-4"></Nav>
              <Nav className="d-flex gap-md-0 gap-lg-4 mt-2">
                <NavLink
                  to={'/'}
                  className={`text-decoration-none  d-flex align-items-center nav-hover fs-6 my-2 ${
                    props.active === 'home' ? `nav-selected` : `nav-item`
                  }`}
                >
                  Home
                </NavLink>
                <NavLink
                  to={'/payment'}
                  className={`text-decoration-none d-flex align-items-center nav-hover fs-6 my-2  ${
                    props.active === 'payment' ? `nav-selected` : `nav-item`
                  }`}
                >
                  Payment
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBar;
