import React from 'react';
import './App.css';
import PayhereModel from './payment/payhereModel';
import { FunctionsProvider, SuspenseWithPerf } from 'reactfire';
import Loader from './components/Loader/Loader';
import AppRoutes from './routes/Routes';
import { getDatabase } from 'firebase/database';
import { DatabaseProvider, useFirebaseApp } from 'reactfire';

import './shared/bootstrap/bootstrap.scss';
import { getFunctions } from 'firebase/functions';

function App() {
  const app = useFirebaseApp();
  const database = getDatabase(app);
  const functions = getFunctions(app);

  // connectFunctionsEmulator(functions, 'localhost', 5001);
  return (
    <SuspenseWithPerf fallback={<Loader />} traceId={'load-application'}>
      <DatabaseProvider sdk={database}>
        <FunctionsProvider sdk={functions}>
          <PayhereModel />

          <AppRoutes />
        </FunctionsProvider>
      </DatabaseProvider>
    </SuspenseWithPerf>
  );
}

export default App;
