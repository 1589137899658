import React, { Fragment } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../components/Home/Home';
import PaymentScreen from '../components/Payment/PaymentScreen';
import PaymentSuccessScreen from '../components/Notices/PaymentSuccess';
import PaymentUnSuccessScreen from '../components/Notices/PaymentUnSuccessScreen';

const AppRoutes = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/payment/success/:orderId" element={<PaymentSuccessScreen />} />
          <Route path="/payment/unsuccess" element={<PaymentUnSuccessScreen />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default AppRoutes;
