import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import NavBar from '../NavBar/NavBar';
import './Home.scss';
import sideImage from './assets/E-Wallet-amico1.webp';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="fade-in">
        <NavBar active="home" background="bg-background" />
        <section className="container my-5 home-container d-flex align-items-center">
          <Row>
            <Col>
              <Row>
                <Col
                  className="align-self-center mt-md-3 mt-sm-3 mt-xs-3"
                  xs={{ order: 2 }}
                  md={{ order: 2 }}
                  lg={{ order: 1 }}
                >
                  <Row>
                    <Col className="title-one">Skill Surf</Col>
                  </Row>
                  <Row>
                    <Col className="title-two">Payments</Col>
                  </Row>

                  <Row>
                    <Col className="py-4 details-text">
                      Official online payment platform of Skill Surf. Make sure to use a valid email
                      address to get the payment receipt.
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="text-white register-button px-4 py-2 "
                        style={{ fontWeight: 600, width: 'auto' }}
                        onClick={() => {
                          navigate('/payment');
                        }}
                      >
                        Make a Payment
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={{ order: 1 }}
                  md={{ order: 1 }}
                  lg={{ order: 2 }}
                  className="d-flex justify-content-center"
                >
                  <img src={sideImage} alt="skill_surf home" className="home-img" />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Home;
